import { getCurrentPage } from "@multimediallc/cb-roomlist-prefetch"
import { getRoomlistDynamicFilters } from "./filters/filtersUtil";
import { HomepageRoomlistContainer } from "./homepageRoomlistContainer"
import type { IRoomlistContainerProps } from "./homepageRoomlistContainer"


// Roomlist container subclass specifically intended for the secondary roomlist on the private shows page.
export class HiddenCamsRoomlistContainer extends HomepageRoomlistContainer {
    protected initUI(props: IRoomlistContainerProps): void {
        super.initUI(props)
        // Update normally-dynamic child components to permanently reflect the hidden cams roomlist
        // /hidden-cams/ is no longer its own page, and corresponds only to the second roomlist on /spy-on-cams/
        this.roomlistMsg.updateMessage({ categorySlug: "hidden-cams" })
        this.roomlist.setCategoryFilters({ "hidden": true })
    }

    public updateContainerFromUrl(): void {
        this.notVisibleRegions?.updateAlertMessage()

        const keywords = new URLSearchParams(window.location.search).get("keywords") ?? ""
        this.searchMsg?.updateMessage(keywords)
        // Update ONLY the dynamic roomlist filters, and reload the current page of rooms
        const pageNum = getCurrentPage(this.pageParam)
        const newFilters = getRoomlistDynamicFilters()
        this.roomlist.updateFilters(newFilters, pageNum)
    }

    protected handlePageChange(pageNum: number): void {
        // Changing pages in the secondary roomlist should scroll back to the top of *the current container*
        // rather than the top of the page, so the user doesn't lose track of which list they were looking at
        this.element.scrollIntoView()
    }

    protected isSecondaryContainer(): boolean {
        return true
    }
}
