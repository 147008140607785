import { ALL, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { isiPad } from "@multimediallc/web-utils/modernizr"
import { addEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { EventRouter } from "../../../common/events"
import { isEnterKey } from "../../../common/eventsUtil"
import { isFilterInPathActive } from "../../../common/featureFlagUtil"
import { addPageAction } from "../../../common/newrelic"
import { genderFilterUpdateFromNav } from "../../components/roomlist/spaHelpers"
import { GenderDropdownToggle } from "../../components/roomlist/subNavHeaderTabs"
import { SearchBlur, SearchInput } from "./searchInput"
import type { ISearchInputProps } from "./searchInput"

export interface IRoomlistSearchInputProps extends ISearchInputProps {
    searchIcon: HTMLElement,
}

export function initSearchInput(searchIconId: string, pushSearches = false): void {
    const searchIcon = document.getElementById(searchIconId)
    if (searchIcon === null) { return }

    searchIcon.style.display = "block"
    const input = new RoomlistSearchInput({
        searchIcon: searchIcon,
        pushSearches: pushSearches,
    })
    searchIcon.parentElement?.insertBefore(input.element, searchIcon)
    if (!isFilterInPathActive()) {
        // Firing this event ensures that the value is set correctly, but is mostly to ensure that
        // the input is appropriately minimized/hidden depending on that value.
        const urlKeywords = new URLSearchParams(window.location.search).get("keywords") ?? ""
        RoomlistSearchInput.searchKeywordValueUpdate.fire(urlKeywords)
    }
}

export class RoomlistSearchInput extends SearchInput<IRoomlistSearchInputProps> {
    private MINIMIZED_CLASS = "minimized"
    private MAXIMIZED_CLASS = "maximized"
    private searchIcon: HTMLElement

    public static searchKeywordValueUpdate = new EventRouter<string>("searchKeywordValueUpdate")

    protected initData(props: IRoomlistSearchInputProps): void {
        super.initData(props)
        this.searchIcon = props.searchIcon
        const searchIconHandler = () => {
            // If input not empty, submit input
            if (this.input.value !== "") {
                this.onSubmitSearchInput()
            }
            // Else, open or close input
            else {
                if (this.input.style.display === "none") {
                    this.showInputBar()
                    window.setTimeout(() => {
                        this.input.focus()
                    })
                    if (isiPad()) {
                        this.input.focus()
                    }
                    addPageAction("SearchBarOpen")
                } else {
                    this.hideInputBar()
                    this.searchIcon.blur()
                    addPageAction("SearchBarClose")
                }
            }
        }

        this.searchIcon.onclick = searchIconHandler
        this.searchIcon.onkeydown = (e: KeyboardEvent) => {
            if (isEnterKey(e)) {
                e.preventDefault()
                searchIconHandler()
            }
        }

        this.searchIcon.onblur = (e: FocusEvent) => {
            SearchBlur.fire(e)
        }

        // Normally, SearchInput.onInputBlur is called in response to SearchBlur events after a timeout.
        // The gender tab dropdown requires the effects of onInputBlur to happen immediately after
        // the toggle to avoid UI flicker
        GenderDropdownToggle.listen((isShown) => {
            if (isShown) {
                this.onInputBlur()
            }
        })
        // Handling for roomlist SPA and trending tags pages. On pages that push to the history stack,
        // this event router makes it simple to update the input and ensure it's collapsed/minimized
        // appropriately for the new value.
        if (isFilterInPathActive()) {
            UrlState.current.listen(["keywords"], (state) => {
                this.setInputKeywords(state.keywords ?? "")
            }, this.element)
        } else {
            RoomlistSearchInput.searchKeywordValueUpdate.listen((keywords: string) => {
                this.setInputKeywords(keywords)
            })
        }
        // Makes sure search suggestions are hidden on history navigation, both for neatness and to avoid
        // stale gendered links.
        addEventListenerPoly("popstate", window, () => {
            this.input.blur()
        })

        // On iOS and mac devices, the search input is cleared and left open. This re-initializes the input on pageshow.
        addEventListenerPoly("pageshow", window, () => {
            window.setTimeout(() => {
                if (isFilterInPathActive()) {
                    this.input.value = UrlState.current.state.keywords ?? ""
                } else {
                    this.input.value = new URLSearchParams(window.location.search).get("keywords") ?? ""
                }
                this.minimizeInput()
            }, 0)
        })

        // Intended for Trending Tags Page, search inputs do not reset on gender change
        if (isFilterInPathActive()) {
            UrlState.current.listen(["genders"], () => {
                this.input.blur()
            }, this.element)
            UrlState.current.listen(ALL, () => {
                // Ensure value is set correctly, input is appropriately minimized, and suggestions are reset.
                // We want this to occur on any state-based navigation even if the keywords haven't changed, as
                // suggestion links depend on other state and we also might need to clear unsubmitted keywords.
                const urlKeywords = new URLSearchParams(window.location.search).get("keywords") ?? ""
                this.setInputKeywords(urlKeywords)
            }, this.element)
        } else {
            genderFilterUpdateFromNav.listen(() => {
                this.input.blur() // Intended for Trending Tags Page, search inputs do not reset on gender change
            })
        }
    }

    // Used to refresh the state of the search input on state/page changes
    protected setInputKeywords(keywords: string): void {
        this.input.value = keywords
        if (this.input.value !== "") {
            this.showInputBar()
        }
        this.minimizeInput()
    }

    protected initUI(props: IRoomlistSearchInputProps): void {
        super.initUI(props)
        this.element.classList.add("RoomlistSearchInput")
        this.input.className = "search_input"
        this.input.style["-webkit-tap-highlight-color"] = "rgba(255, 255, 255, 0)"
        this.input.style["-webkit-focus-ring-color"] = "rgba(255, 255, 255, 0)"
        if (this.input.value !== "") {
            this.showInputBar()
        }
        this.minimizeInput()
    }

    public onSubmitSearchInput(): void {
        this.minimizeInput()
        super.onSubmitSearchInput()
    }

    protected onInputBlur(): void {
        super.onInputBlur()
        this.minimizeInput()
    }

    protected onInputFocus(): void {
        super.onInputFocus()
        this.maximizeInput()
    }

    protected onInputDeleteClick(e: PointerEvent): void {
        super.onInputDeleteClick(e)
        if (isiPad()) {
            // Fix for ipad to prevent caret not adjusting when input width is maximized.
            this.maximizeInput()
        }
    }

    private maximizeInput(): void {
        this.input.classList.add(this.MAXIMIZED_CLASS)
        this.input.classList.remove(this.MINIMIZED_CLASS)
    }

    private minimizeInput(): void {
        if (this.input.value === "") {
            if (this.input.style.display !== "none") {
                this.hideInputBar()
            }
        } else {
            this.input.classList.add(this.MINIMIZED_CLASS)
            this.input.classList.remove(this.MAXIMIZED_CLASS)
        }
    }
}
