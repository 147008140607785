import { ArgJSONMap } from "@multimediallc/web-utils"
import { addEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { getCb } from "../../../common/api"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { Gender } from "../../../common/genders"
import { addPageAction } from "../../../common/newrelic"
import { printCatch } from "../../../common/promiseUtils"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { RoomReload } from "../../roomList"
import { hashtagsUrl, hashtagUrl } from "../../util/hashtagsUtils"

interface HashtagTickerProps {
    onTagClick?: (event: MouseEvent) => void
}
interface HashtagTickerState {
    genders: Gender[]
}

export class HashtagTicker extends HTMLComponent<HTMLDivElement, HashtagTickerProps, HashtagTickerState> {
    private props: HashtagTickerProps
    constructor(props: HashtagTickerProps) {
        super(props)
        this.props = props
        RoomReload.getInstance().addOnLoadHandler(() => {
            this.updateState()
        })
    }

    protected createElement(props: HashtagTickerProps): HTMLDivElement {
        return <div className="hashtag-ticker-container" />
    }

    updateState(): void {
        super.updateState()
        // TopHashtagsForm Django form won't except "t" as a value
        const gendersForApi = this.state.genders.map((gender: Gender) => {
            return gender === Gender.Trans ? Gender.OldTrans : gender
        })
        const urlQuery = new URLSearchParams(gendersForApi.map(g => ["genders", g]))
        getCb(`api/ts/hashtags/top_tags/?${urlQuery.toString()}`).then(xhr => {
            const json = new ArgJSONMap(xhr.responseText)
            const allTags = json.getStringList("all_tags")
            this.updateElement(allTags)
        }).catch(printCatch)
    }

    private updateElement(allTags: string[]): void {
        this.element.querySelector("#hashtag_ticker")?.remove()
        if (allTags.length > 0) {
            this.element.appendChild(
                <div id="hashtag_ticker" data-paction="HomepageHashtag" data-paction-send-position="true" data-testid="hashtag-ticker">
                    <a id="more_hashtags_link" href={hashtagsUrl(this.getGender())} data-testid="more-hashtags-link">{i18n.moreTags}</a>
                    {this.tagsRow(allTags)}
                </div>,
            )
        }
    }

    private tagsRow(tags: string[]): HTMLAnchorElement[] {
        return tags.map((tag) => {
            return this.bindTagsPageAction(<a
                className="tag-link"
                href={hashtagUrl(tag, this.getGender())}
                onClick={this.props.onTagClick}
            >#{tag}</a>)
        })
    }

    private getGender(): Gender {
        return this.state.genders.length > 0 ? this.state.genders[0] : Gender.All
    }

    private bindTagsPageAction(hashtag: HTMLAnchorElement): HTMLAnchorElement {
        addEventListenerPoly("click", hashtag, () => {
            addPageAction("RoomsFilterTagsClicked", {
                "tagName": hashtag.textContent ?? undefined,
                "source": "HashtagTicker",
            })
        })
        return hashtag
    }
}
