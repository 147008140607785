import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { dom } from "../../../common/tsxrender/dom"

export interface MetaState {
    content: string,
}

export interface MetaProps extends MetaState {
    name?: string,
    property?: string,
}

export class MetaComponent extends HTMLComponent<HTMLMetaElement, MetaProps, MetaState> {
    createElement(props: MetaProps): HTMLMetaElement {
        return <meta {...(props.name !== undefined && props.name !== "" && { name: props.name })}
                     {...(props.property !== undefined && { property: props.property })}
                     content={ props.content }
                     bind={{ content: () => this.state.content }}
        />
    }
}
