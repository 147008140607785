import { UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { addEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { recordInteractionIfAffiliatePage } from "../../../common/affiliates"
import { getEligibleTargetAnchor } from "../../../common/DOMutils"
import { JoinOverlay, JoinOverlayAnchor } from "../../../common/theatermodelib/joinOverlay"
import { parseQueryString } from "../../../common/urlUtil"
import {
    isRoomRoomlistSpaEligiblePage,
    setJoinOverlayDismissed,
    shouldShowJoinOverlay,
} from "./spaHelpers"

export class SpaJoinOverlay extends JoinOverlay {
    constructor(private initialLink: HTMLAnchorElement) {
        super({
            onHide: () => {
                setJoinOverlayDismissed()
                if (isRoomRoomlistSpaEligiblePage()) {
                    const urlSearchParams = new URLSearchParams(window.location.search)
                    urlSearchParams.delete("join_overlay")
                    const queryString = urlSearchParams.toString()
                    const url = queryString !== "" ? `?${queryString}` : window.location.pathname
                    UrlState.current.replaceUrl(url)
                }
            },
        })
    }

    // For SPA roomlists, the existing join overlay behavior plays poorly with lazy-loading/re-rendering
    // page elements. Instead of initializing JoinOverlayAnchors for all relevant links on the page, this
    // class instead constructs an ad-hoc Anchor component so the overlay can be initialized in response
    // to link clicks, rather than ahead of time.
    public initWithoutAnchor(): void {
        const overlayAnchor = new JoinOverlayAnchor(this.initialLink)
        // We don't want to call commandeer() on the overlayAnchor because we don't want to change the HREF value.
        // However, initAndShow requires initialHref to be defined for constructing the register later link.
        overlayAnchor.initialHref = this.initialLink.href
        this.initAndShow(overlayAnchor)
        // Typically initAndShow also handles swapping the onclick handlers for the original link with the
        // register later link, since commandeer() substitutes the original click handler. However, we don't
        // just want the original immediate onclick handler, we want the click to be handled as an SPA nav.
        // This hack is the simplest way to make sure that happens despite not doing the typical setup --
        // we overwrite the value from the (undefined) initialOnClick and directly click the original link.
        this.registerLaterLink.onclick = (event: MouseEvent) => {
            event.preventDefault()
            this.initialLink.click()
        }
    }
}

function showJoinOverlay(event: MouseEvent | KeyboardEvent): void {
    const anchorTarget = getEligibleTargetAnchor(event.target)
    if (anchorTarget !== undefined) {
        // The roomlist entrypoint disables the default join overlay for SPA in setupJoinOverlay() since it doesn't
        // play nicely. If join overlay is enabled and this link should trigger it, explicitly show it and halt event
        // propagation to make sure other click handlers (and SPA navigation) don't occur in the background.
        if (shouldShowJoinOverlay(anchorTarget)) {
            const overlay = new SpaJoinOverlay(anchorTarget)
            window.scrollTo(0, 0)
            overlay.initWithoutAnchor()
            event.stopPropagation()
            event.preventDefault()
            recordInteractionIfAffiliatePage()
        }
    }
}

export function bindSpaJoinOverlay(): void {
    if (parseQueryString(window.location.search)["join_overlay"] !== undefined) {
        addEventListenerPoly("mousedown", document, (event: MouseEvent) => {
            const isRightClick = event.button === 2
            if (!isRightClick) {
                showJoinOverlay(event)
            }
        })
        addEventListenerPoly("keydown", document, (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                showJoinOverlay(event)
            }
        })
    }
}
