import { AllRegionValues, getRegionCategoryFilter, shouldShowHomepageFilters } from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { pageContext } from "../../interfaces/context"
import { REGION_MAPPING } from "./filters/constants";
import type { BoundListener } from "../../../common/events"
import type { AdvancedSearchOptions } from "../../advancedSearchOptions"
import type { Region } from "@multimediallc/cb-roomlist-prefetch";


interface INotVisibleRegionsAlertProps {
    advancedSearchOptions: AdvancedSearchOptions
}

export class NotVisibleRegionsAlert extends HTMLComponent<HTMLParagraphElement, INotVisibleRegionsAlertProps> {
    private advancedSearchOptions: AdvancedSearchOptions
    private selectedRegions: string
    private regionChangeListener: BoundListener<string>

    protected createElement(props: INotVisibleRegionsAlertProps): HTMLParagraphElement {
        return <p className="filters-info"></p>
    }

    private alertLinks(notVisibleRegions: string[]) {
        return <span data-testid="not-visible-regions-filters-info">
            <span>{i18n.notShowingFromRegions} </span>
            {notVisibleRegions.map((region, index) => <span>
                <a className="advanced_search_region" href="#">{region}</a>
                <span>{index === notVisibleRegions.length - 1 ? "" :
                    (index < notVisibleRegions.length - 2 ? ", " : ` ${i18n.and} `)}</span>
            </span>)}
        </span>
    }

    protected initUI(props: INotVisibleRegionsAlertProps): void {
        super.initUI(props)
        this.selectedRegions = pageContext.current.regions
        this.advancedSearchOptions = props.advancedSearchOptions
        this.regionChangeListener = this.advancedSearchOptions.regionsChanged.listen((regions) => {
            this.selectedRegions = regions !== "" ? regions : AllRegionValues.join(",")
            this.updateAlertMessage()
        })
        this.updateAlertMessage()
    }

    updateAlertMessage(): void {
        // When viewing a region category page, user region selection is ignored, so determine
        // whether a region filter is currently being forced, to handle the messaging accordingly.
        const forcedRegion = getRegionCategoryFilter()
        const notVisibleRegions = forcedRegion === undefined ? getExcludedRegionTitles(this.selectedRegions) : getExcludedRegionTitles(forcedRegion)
        this.element.textContent = ""  // Remove all element contents
        if (notVisibleRegions.length > 0 && !shouldShowHomepageFilters()) {
            const alertEl = this.alertLinks(notVisibleRegions)
            this.element.appendChild(alertEl)
        }
        window.setTimeout(() => {
            this.advancedSearchOptions.bindAdvancedSearchRegion()
        })
    }

    public dispose(): void {
        this.regionChangeListener.removeListener()
    }
}

/**
 * @param: string of Regions seperated by comma
 * @return: string[] - Excluded regions titles for the given selected regions
 */
function getExcludedRegionTitles(selectedRegions: string): string[] {
    const regions = AllRegionValues.filter((region: Region) => {
        return !selectedRegions.includes(region)
    })
    return regions.map((region) => { return REGION_MAPPING.get(region) ?? "" })
}
