import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { dom } from "../../../common/tsxrender/dom"

export interface LinkState {
    href: string,
    title?: string,
}

export interface LinkProps extends LinkState {
    rel: string,
    hreflang?: string,
    media?: string,
    type?: string,
}

export class LinkComponent extends HTMLComponent<HTMLLinkElement, LinkProps, LinkState> {
    private props: LinkProps

    createElement(props: LinkProps): HTMLLinkElement {
        this.props = props
        return <link rel={this.props.rel}
            {...(this.props.title !== undefined && this.props.title !== "" && { title: this.props.title })}
            {...(this.props.media !== undefined && this.props.media !== "" && { media: this.props.media })}
            {...(this.props.type !== undefined && this.props.type !== "" && { type: this.props.type })}
            {...(this.props.hreflang !== undefined && this.props.hreflang !== "" && { hrefLang: this.props.hreflang })}
            href={this.props.href}
            bind={{
                href: () => this.state.href,
            }}
        />
    }

    updateState(): void {
        super.updateState()
        if (this.state.title !== undefined && this.state.title !== "") {
            this.element.title = this.state.title
        } else {
            this.element.removeAttribute("title")
        }
    }
}
