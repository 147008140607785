import { HTMLComponent } from "../../../../common/defui/htmlComponent"
import { isArrowDownKey, isArrowUpKey } from "../../../../common/eventsUtil"
import { i18n } from "../../../../common/translation"
import { dom } from "../../../../common/tsxrender/dom"
import { addColorClass, removeColorClass } from "../../../colorClasses"

interface TagSuggestionsProps {
    onTagClick: (tag: string) => void,
}

const MAX_TAG_LIST_LENGTH = 10

// This class takes autocomplete results and displays them, with highlighting/bolding functionalities
export class TagAutocompleteList extends HTMLComponent<HTMLDivElement, TagSuggestionsProps> {
    private props: TagSuggestionsProps
    private keyword: string
    private currentIndex: number
    private currentTagsList: string[]

    protected createElement(): HTMLDivElement {
        return <div className="tagSuggestionsDiv"/>
    }

    protected initData(props: TagSuggestionsProps): void {
        this.props = props
    }

    private clearList(): void {
        this.element.textContent = ""
        this.currentTagsList = []
        this.currentIndex = -1
    }

    private addSuggestionItem(tag: string): void {
        const itemIndex = this.element.children.length
        const newItem = <div className="suggestion"
                             title={tag}
                             data-testid="filter-hashtag-suggestion"
                             onMouseDown={() => {this.props.onTagClick(tag)}}
                             onMouseEnter={() => {
                                 this.singleHighlight(newItem, itemIndex)
                             }}
                             onMouseLeave={() => {
                                 removeColorClass(newItem, "highlight")
                                 this.currentIndex = -1
                             }}
        >
            {this.generateTagText(tag)}
        </div>
        this.element.appendChild(newItem)
    }

    // Bolds keyword within tag suggestions
    private generateTagText(tag: string): HTMLDivElement {
        if (this.keyword === "") {
            return <div className="tagText">{`#${tag}`}</div>
        }

        const tagSplit = tag.split(this.keyword)
        const thirdPortionIndex = tag.indexOf(this.keyword) + this.keyword.length
        return <div className="tagText">
            <span>{`#${tagSplit[0]}`}</span>
            <span className="keyword">{this.keyword}</span>
            <span>{tag.slice(thirdPortionIndex)}</span>
        </div>
    }

    // Update autocomplete with new results, or "Not found" message if none given
    public updateResults(newResults: string[], keyword: string): void {
        this.clearList()
        if (newResults.length === 0) {
            this.element.appendChild(<div className="notFoundText" data-testid="search-not-found">{i18n.notFound}</div>)
        } else {
            this.keyword = keyword.toLowerCase()
            const resultsList = keyword === "" ? newResults.slice(-MAX_TAG_LIST_LENGTH) : newResults
            let tagListCount = 0
            resultsList.reverse().forEach((tagName: string) => {
                if (tagListCount < MAX_TAG_LIST_LENGTH) {
                    this.addSuggestionItem(tagName)
                    tagListCount = tagListCount + 1
                }
                this.currentTagsList.push(tagName)
            })
        }
    }

    // Highlights list item upon arrow up/down key
    public highlightViaKeyNav(e: KeyboardEvent): void {
        if (this.element.children.length === 0) {
            return
        }

        const highestIndex = this.element.children.length - 1
        let newIndex = -1
        if (isArrowDownKey(e)) {
            newIndex = this.currentIndex + 1 <= highestIndex ? this.currentIndex + 1 : 0
        } else if (isArrowUpKey(e)) {
            newIndex = this.currentIndex - 1 >= 0 ? this.currentIndex - 1 : highestIndex
        }

        if (newIndex > -1) {
            const highlightItem = this.element.children.item(newIndex)
            if (highlightItem !== null) {
                this.singleHighlight(highlightItem as HTMLDivElement, newIndex)
            }
        }
    }

    private singleHighlight(item: HTMLDivElement, itemIndex: number): void {
        this.unhighlightList()
        addColorClass(item, "highlight")
        this.currentIndex = itemIndex
    }

    // There is at most 1 highlighted item at a time, and that item is indicated by the this.currentIndex
    public unhighlightList(): void {
        if (this.currentIndex > -1) {
             const highlightedItem = this.element.children.item(this.currentIndex)
             if (highlightedItem !== null) {
                 highlightedItem.classList.remove("highlight")
             }
        }
        this.currentIndex = -1
    }

    public getHighlightedText(): string | undefined {
        if (this.currentIndex > -1) {
            return this.currentTagsList[this.currentIndex]
        }
    }

    public isTagValid(searchTag: string): boolean {
        return this.currentTagsList.includes(searchTag)
    }
}
