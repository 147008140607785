import {
    DEFAULT_PAGESIZE,
    getAPIParamsFromURLState,
    getCategorySlug,
    getCurrentPage,
    getPaginationAPIParams,
    HOMEPAGE_KEYS,
    homepageFiltersCurrentlyApplied,
    isFollowedCams,
    PageType,
    ROOMLIST_API_URL,
    ShowType,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { addDeferedEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { ListenerGroup } from "../../../common/events"
import { isFilterInPathActive, isSpyPriceFiltersActive } from "../../../common/featureFlagUtil"
import { dom } from "../../../common/tsxrender/dom"
import { SearchInput } from "../../ui/searchBar/searchInput"
import { Pagination } from "../pagination/pagination"
import { getRoomlistCategoryFilters, getRoomlistDynamicFilters } from "./filters/filtersUtil"
import { NoRoomsAvailableNotification } from "./noRoomsAvailableNotification"
import { NotVisibleRegionsAlert } from "./NotVisibleRegionsAlert"
import { NumberOfRoomsMessage } from "./numberOfRoomsMessage"
import { PaginatedApiRoomList } from "./paginatedApiRoomList"
import { RoomlistMessage } from "./roomlistMessage"
import { RoomReloadErrorNotification } from "./roomReloadErrorNotification"
import { SearchResultsMessage } from "./searchResultsMessage"
import type { AdvancedSearchOptions } from "../../advancedSearchOptions"
import type { IRoomListAPIParams, IURLState } from "@multimediallc/cb-roomlist-prefetch"

export interface IRoomlistContainerProps {
    advancedSearchOptions?: AdvancedSearchOptions
    animate: boolean
    showLocation: boolean
    appName?: string
    pageParam?: string  // What URL param corresponds to this container's pagination. Defaults to "page"
    isSecondary?: boolean
}

export class HomepageRoomlistContainer extends HTMLComponent<HTMLDivElement, IRoomlistContainerProps> {
    protected notVisibleRegions?: NotVisibleRegionsAlert
    protected roomlistMsg: RoomlistMessage
    protected searchMsg?: SearchResultsMessage
    protected roomlist: PaginatedApiRoomList
    protected listeners: ListenerGroup
    protected appName?: string
    protected pageParam: string
    private pagination: Pagination
    private roomReloadErrorNotification: RoomReloadErrorNotification
    private noRoomsAvailableNotification: NoRoomsAvailableNotification
    private numberOfRoomsMessage: NumberOfRoomsMessage
    private props: IRoomlistContainerProps

    constructor(props: IRoomlistContainerProps) {
        super(props)
    }

    protected createElement(props: IRoomlistContainerProps): HTMLDivElement {
        if (isFilterInPathActive()) {
            this.pageParam = props.isSecondary === true ? "pageb" : "page"
        } else {
            this.pageParam = props.pageParam ?? "page"
        }
        return <div className="roomlist_container endless_page_styles" data-testid="room-list-container">
            {isFilterInPathActive() ? "" : <NotVisibleRegionsAlert classRef={(c) => { this.notVisibleRegions = c }}
                    advancedSearchOptions={props.advancedSearchOptions as AdvancedSearchOptions}
            />}
            <RoomlistMessage
                isSecondContainer={props.isSecondary ?? false}
                classRef={(c) => { this.roomlistMsg = c }}
                appName={props.appName}
            />
            <SearchResultsMessage classRef={(c) => { this.searchMsg = c }} />
            <RoomReloadErrorNotification classRef={(c) => { this.roomReloadErrorNotification = c }} />
            <NoRoomsAvailableNotification classRef={(c) => { this.noRoomsAvailableNotification = c }} />
            <PaginatedApiRoomList classRef={(c) => { this.roomlist = c }}
                animate={props.animate}
                showLocation={props.showLocation}
                apiUrl={ROOMLIST_API_URL}
                pageSize={DEFAULT_PAGESIZE}
                pageParam={this.pageParam}
            />
            <NumberOfRoomsMessage isSecondary={props.isSecondary ?? false} classRef={(c) => { this.numberOfRoomsMessage = c }} />
            <div id="roomlist_pagination" data-testid="room-list-pagination-component">
                <Pagination classRef={(c) => { this.pagination = c }}
                    itemsPerPage={DEFAULT_PAGESIZE}
                    onPageChange={(pageNumber) => this.onPageChange(pageNumber)}
                    reloadOnPageChange={false}
                    addLastPageAction={true}
                    pageParam={this.pageParam}
                    makeResponsive={true}
                />
            </div>
        </div>
    }

    protected initData(props: IRoomlistContainerProps): void {
        this.props = props
        this.listeners = new ListenerGroup()
        this.appName = props.appName
        if (isFilterInPathActive()) {
            UrlState.current.listen([...HOMEPAGE_KEYS, "pageType"], (state: IURLState) => {
                if (this.isActive()) {
                    this.loadRooms()
                    this.showElement()
                } else {
                    this.hideElement()
                }
            }, this.element)
            if (this.isActive()) {
                // Check for a prefetch request on window, if found pass it through and then clear it so it's only used once
                // (No real need for a conditional check, if it's undefined then the delete does nothing)
                const prefetchWindowKey = "prefetchPromise" as keyof Window
                const prefetchPromise: Promise<string> = window[prefetchWindowKey]
                this.loadRooms(props.isSecondary === true ? undefined : prefetchPromise)
                delete window[prefetchWindowKey]
                this.showElement()
            } else {
                this.hideElement()
            }
        }
    }

    private isActive() {
        return UrlState.current.state.pageType === PageType.HOME &&
            (this.props.isSecondary !== true || UrlState.current.state.showType === ShowType.PRIVATE)
    }

    protected initUI(props: IRoomlistContainerProps): void {
        // Bind filter change listeners to update roomlist automatically
        if (!isFilterInPathActive()) {
            if (props.advancedSearchOptions !== undefined) {
                this.listeners.add(props.advancedSearchOptions.regionsChanged.listen(() => this.handleAdvancedSearchOptionChange()))
            }
            this.listeners.add(SearchInput.onSubmit.listen((event: string) => this.handleSearch(event)))
        }
        this.roomReloadErrorNotification.hideElement()

        // Pageshow is also fired on initial document load just after the `load` event, but since the entrypoint code runs
        // _during_ load, we need to ignore it the very first time it fires or we end up loading the initial roomlist twice.
        // It would be nicer to just check event.persisted, but unfortunately that's been unreliable for almost a decade:
        // https://bugs.chromium.org/p/chromium/issues/detail?id=344507
        if (isFilterInPathActive() && props.isSecondary !== true) {
            addDeferedEventListenerPoly("pageshow", window, () => {
                // The function of the pageshow refresh is to avoid showing wrong following states from the cache, and follow
                // stars are already force-updated in the base RoomList class. It's only needed for followed-cams because we
                // need to change the actual room cards shown in the roomlist itself, not just their follow stars.
                if (isFollowedCams()) {
                    this.loadRooms()
                }
            })
        }
    }

    public dispose(): void {
        if (!isFilterInPathActive()) {
            this.notVisibleRegions?.dispose()
            this.searchMsg?.dispose()
            this.roomlist.dispose()
            this.listeners.removeAll()
        }
    }

    public handleRoomRefresh(): void {
        this.loadRooms()
    }

    protected handleAdvancedSearchOptionChange(): void {
        this.roomlist.updateFilters(getRoomlistDynamicFilters())
        this.loadRooms()
    }

    protected handleSearch(searchVal: string): void {
        const newFilters = getRoomlistDynamicFilters(searchVal)
        this.roomlist.updateFilters(newFilters)
        // Applying a search filter should jump back to the first page of results
        this.roomlist.setPage(1)
        this.loadRooms()
    }


    // Automatically update the contents of the container's child components based on the current document location.
    // Handles updating everything EXCEPT FOR THE ACTUAL ROOMLIST CONTENTS, which can be explicitly loaded or handled
    // from a prefetch request by calling updateRooms()
    public updateContainerFromUrl(): void {
        // Update the not-visible regions alert in case we've navigated to/from a region category page
        this.notVisibleRegions?.updateAlertMessage()

        // Update the roomlist message to reflect the current page category if any
        // appName is only displayed if the category slug is for an app usage index roomlist
        const categorySlug = getCategorySlug()
        this.roomlistMsg.updateMessage({
            categorySlug,
            appName: this.appName,
        })
        // Update search results message text since URL search keywords may have changed from navigation
        const keywords = new URLSearchParams(window.location.search).get("keywords") ?? ""
        this.searchMsg?.updateMessage(keywords)
        // Update roomlist filters and reload page of rooms
        const pageNum = getCurrentPage(this.pageParam)
        const newCategoryFilters = getRoomlistCategoryFilters()
        const newFilters = getRoomlistDynamicFilters()
        this.roomlist.setCategoryFilters(newCategoryFilters)
        this.roomlist.updateFilters(newFilters, pageNum)
    }

    public updateRooms(prefetchPromise?: Promise<string>): void {
        if (prefetchPromise !== undefined) {
            this.loadRooms(prefetchPromise)
        } else {
            this.loadRooms()
        }
    }

    /**
     * Loads room from the API call updating all the corresponding components.
     * @param prefetchPromise Optional, if provided will attempt to get results from this promise rather than
     *     making a new API request (will retry with new requests as typical upon failure, however)
     */
    loadRooms(prefetchPromise?: Promise<string>): void {
        this.roomlist.fetchRooms(prefetchPromise, this.getAPIFilters()).then(({ totalCount, matchedCount, page }) => {
            this.roomReloadErrorNotification.hideElement()
            if (isFilterInPathActive()) {
                page = this.props.isSecondary === true ? UrlState.current.state.pageb : UrlState.current.state.page
            }
            this.onRoomsLoaded(page ?? 1, matchedCount, totalCount);
        }).catch((err) => {
            error("Failed to load roomlist page after retrying", err)
            this.roomReloadErrorNotification.showElement()
        })
    }

    // eslint-disable-next-line complexity -- after feature flag removal, this method will be simple
    private onRoomsLoaded(page: number, matchedCount: number, totalCount: number): void {
        this.pagination.setState({ currentPage: page ?? 1, totalItems: matchedCount })
        // The NumberOfRoomsMessage and the NoRoomsAvailableNotification are mutually exclusive -- the former
        // is only shown on filter-panel-enabled pages WITH filters applied, and the latter on panel-DISABLED
        // pages (in theory.) Since `hasFilters()` includes a check for the filter panel toggle existing, we
        // can just update both states based on that. Technically this returns false if there are no applied
        // filters as well, even on panel-enabled pages, but prod should never show an empty list w/o filters.
        // This way we make sure exactly ONE message gets shown even if you're on a devserver with no rooms.
        this.numberOfRoomsMessage.setState({
            total: totalCount ?? 0,
            matched: matchedCount ?? 0,
            isLastPage: this.pagination.isLastPage(),
            hasFilter: homepageFiltersCurrentlyApplied(),
        })
        this.noRoomsAvailableNotification.setState({
            numRooms: this.roomlist.rooms.length,
            filterPanelActive: homepageFiltersCurrentlyApplied(),
        })
        if (!isFilterInPathActive()) {
            // The SearchResultsMessage and NoRoomsAvailableNotification are also mutually exclusive when on
            // non-filter-panel-enabled pages. When NoRoomsAvailableNotification shows upon empty list, there is no
            // need to show the SearchResultsMessage as it's a redundant message.
            this.searchMsg?.setState({
                numRooms: this.roomlist.rooms.length,
                filterPanelActive: homepageFiltersCurrentlyApplied(),
            })
            if (matchedCount > 0 && this.roomlist.rooms.length === 0) {
                // If we didn't get any rooms but matchedCount > 0 then we must be past the end of the results,
                // so jump back to page 1 and try loading rooms again.
                this.resetPagination()
                if (page !== 1) {
                    this.loadRooms()
                }
            }
        }
        // If we didn't get any rooms but matchedCount > 0 then we must be past the end of the results,
        // so jump back to page 1 and try loading rooms again.
        if (isFilterInPathActive()) {
            const shouldResetToPageOne = page > 1 && matchedCount > 0 && this.roomlist.rooms.length === 0
            if (shouldResetToPageOne) {
                UrlState.current.setPartialState(this.props.isSecondary === true ? { pageb: 1 } : { page: 1 })
            }
        }
    }

    protected getAPIFilters(): IRoomListAPIParams | undefined {
        if (!isFilterInPathActive()) {
            return undefined
        }
        const params = getAPIParamsFromURLState(UrlState.current.state)
        if (isSpyPriceFiltersActive()) {
            // Prevents filtering by private price on all of spy-on-cams
            if (UrlState.current.state.showType === ShowType.PRIVATE) {
                delete params.private_prices
            }
            // Prevents filtering by spy show prices on community controlled cams
            if (this.props.isSecondary === true) {
                delete params.spy_show_prices
            }
        }
        if (this.props.isSecondary !== true) {
            return params
        }
        return {
            ... params, [ShowType.PRIVATE]: false, [ShowType.HIDDEN]: true,
            ...getPaginationAPIParams(UrlState.current.state.pageb),
        }
    }

    // Convenience method for resetting page state + URL param *without* triggering a new room load
    public resetPagination(): void {
        // Should not create a new history state, since we reset pagination for empty results that
        // we want to replace with (usually non-empty) first-page results in the history stack
        UrlState.current.setPartialState({ [this.pageParam]:  1 }, true)
        this.roomlist.setPage(1)
    }

    private onPageChange(pageNumber: number): void {
        if (!isFilterInPathActive()) {
            // Load the new page of rooms
            this.roomlist.setPage(pageNumber)
            this.loadRooms()
            UrlState.current.setPartialState({ [this.pageParam]: pageNumber })
            window.scrollTo(0, 0)
        } else {
            UrlState.current.setPartialState(this.props.isSecondary === true ? { pageb: pageNumber } : { page: pageNumber })
            // Changing pages in the main roomlist should scroll back to the top of the page,
            // to simulate an ordinary page navigation and to re-orient the user
            if (this.props.isSecondary !== true) {
                // Changing pages in the main roomlist should scroll back to the top of the page,
                // to simulate an ordinary page navigation and to re-orient the user
                window.scrollTo(0, 0)
            } else {
                // Changing pages in the secondary roomlist should scroll back to the top of *the current container*
                // rather than the top of the page, so the user doesn't lose track of which list they were looking at
                this.element.scrollIntoView()
            }
        }
    }
}
