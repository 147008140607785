import { getCategorySlug, shouldShowHomepageFilters, ShowType, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { isFilterInPathActive } from "../../../common/featureFlagUtil"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { currentSiteSettings } from "../../siteSettings"

interface IRoomlistMessageProps {
    categorySlug?: string
    appName?: string
    isSecondContainer?: boolean
}

function isRoomlistMessageSlug(slug: string): slug is keyof typeof ROOMLIST_MESSAGE_MAP {
    return Object.keys(ROOMLIST_MESSAGE_MAP).includes(slug)
}

export class RoomlistMessage extends HTMLComponent<HTMLDivElement, IRoomlistMessageProps> {
    constructor(props: IRoomlistMessageProps) {
        super(props)
    }

    protected initUI(props: IRoomlistMessageProps): void {
        if (isFilterInPathActive()) {
            UrlState.current.listen(["ageMax", "ageMin", "apps", "aspApp", "showType"], () => {
                this.updateState()
            }, this.element)
        } else {
            this.updateMessage(props)
        }
    }

    private getMessageString(props: IRoomlistMessageProps): string {
        if (isFilterInPathActive()) {
            return this.getMessageFromUrlState(props);
        } else {
            const categorySlug = props.categorySlug ?? ""
            let messageString

            // App name does not update dynamically, so only show the "users running X" message if the category slug matches
            if (["app", "current_app_use"].includes(categorySlug) && props.appName !== undefined) {
                messageString = i18n.usersRunningAppMessage(props.appName)
            } else {
                messageString = isRoomlistMessageSlug(categorySlug) ? ROOMLIST_MESSAGE_MAP[categorySlug] : ""
            }

            if (shouldShowHomepageFilters()) {
                messageString = ""
            }

            return messageString
        }
    }

    private getMessageFromUrlState(props: IRoomlistMessageProps): string {
        if ((UrlState.current.state.apps !== undefined || UrlState.current.state.aspApp !== undefined) && props.appName !== undefined) {
            return i18n.usersRunningAppMessage(props.appName)
        } else {
            const category = getCategorySlug()
            if (props.isSecondContainer ?? false) {
                return i18n.camCategoryHidden
            } else {
                return ROOMLIST_MESSAGE_MAP_SHORT.get(category) ?? ""
            }
        }
    }

    private getPremiumDescription(props: IRoomlistMessageProps): string | undefined {
        if (isFilterInPathActive()) {
            if (UrlState.current.state.showType === ShowType.HIDDEN || (
                UrlState.current.state.showType === ShowType.PRIVATE && props.isSecondContainer === true
            )) {
                return i18n.categoryDescriptionHidden
            } else if (UrlState.current.state.showType === ShowType.PRIVATE) {
                return i18n.categoryDescriptionPrivate(currentSiteSettings.siteName)
            }
        } else {
            const categorySlug = props.categorySlug ?? ""
            let premiumDesc
            const isHidden = (categorySlug === "hidden-cams")
            const isPrivate = (categorySlug === "spy-on-cams")

            // Handle subheader descriptions for private cams page
            if (isHidden) {
                premiumDesc = i18n.categoryDescriptionHidden
            } else if (isPrivate) {
                premiumDesc = i18n.categoryDescriptionPrivate(currentSiteSettings.siteName)
            }

            return premiumDesc
        }
    }

    public updateMessage(props: IRoomlistMessageProps): void {
        const messageString = this.getMessageString(props)
        const premiumDesc = this.getPremiumDescription(props)

        this.removeAllDOMChildren()
        this.element.appendChild(<h2 data-testid={messageString.toLowerCase().replace(/[ \/]/g, "-").replace(/[()\/]/g,"").replace(/--/g,"")}>{messageString}</h2>)
        if (premiumDesc !== undefined) {
            this.element.appendChild(<p className="premium-desc">{premiumDesc}</p>)
        }
    }

    protected createElement(props: IRoomlistMessageProps): HTMLDivElement {
        return (
            <div className="roomlist-msg">
                {isFilterInPathActive() && <h2 data-testid="roomlist-message" bind={{
                    text: () => this.getMessageString(props),
                }}>
                </h2>}
                {isFilterInPathActive() && <p bind={{
                    text: () => this.getPremiumDescription(props),
                }} className="premium-desc"></p>}
            </div>
        )
    }
}

// NOTE mirrors the backend `search_message_display` dict used by both the category_title
// and chat_display_search_message tags, but with ONLY stuff pertinent to the roomlist message
const ROOMLIST_MESSAGE_MAP = {
    "north-american-cams": i18n.camCategoryNorthAmerica,
    "south-american-cams": i18n.camCategorySouthAmerica,
    "asian-cams": i18n.camCategoryAsia,
    "euro-russian-cams": i18n.camCategoryEuroRussia,
    "other-region-cams": i18n.camCategoryOtherRegion,
    "teen-cams": i18n.camCategoryTeen,
    "18to20-cams": i18n.camCategory18to20,
    "18to21-cams": i18n.camCategory18to21,
    "20to30-cams": i18n.camCategory20to30,
    "30to50-cams": i18n.camCategory30to50,
    "21to35-cams": i18n.camCategory21to35,
    "mature-cams": i18n.camCategoryMature,
    "exhibitionist-cams": i18n.camCategoryExhib,
    "spy-on-cams": i18n.camCategoryPrivate,
    "hidden-cams": i18n.camCategoryHidden,
    "new-cams": i18n.camCategoryNewCams,
    "gaming-cams": i18n.camCategoryGaming,
    "6-tokens-per-minute-private-cams": i18n.camCategory6TkPerMin,
    "12-tokens-per-minute-private-cams": i18n.camCategory12TkPerMin,
    "18-tokens-per-minute-private-cams": i18n.camCategory18TkPerMin,
    "30-tokens-per-minute-private-cams": i18n.camCategory30TkPerMin,
    "60-tokens-per-minute-private-cams": i18n.camCategory60TkPerMin,
    "12-18-tokens-per-minute-private-cams": i18n.camCategory12to18TkPerMin,
    "30-42-tokens-per-minute-private-cams": i18n.camCategory30to42TkPerMin,
    "60-72-tokens-per-minute-private-cams": i18n.camCategory60to72TkPerMin,
    "90-tokens-per-minute-private-cams": i18n.camCategory90TkPerMin,
}

const ROOMLIST_MESSAGE_MAP_SHORT = new Map<string, string>([
    ["mature-cams", i18n.camCategoryMature],
    ["exhibitionist-cams", i18n.camCategoryExhib],
    ["spy-on-cams", i18n.camCategoryPrivate],
    ["hidden-cams", i18n.camCategoryHidden],
    ["new-cams", i18n.camCategoryNewCams],
    ["gaming-cams", i18n.camCategoryGaming],
])
