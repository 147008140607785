import {
    AgeCategoryToFilterRange,
    CamTypeCategoryToFilterKey,
    getCamTypeFilter,
    getCategorySlug,
    getCleanedPathname,
    getPageHashtag,
    getParamsMinMaxAgeFilter,
    getParamsRegionFilters,
    getParamsRoomSizeFilter,
    getParamsSpokenLanguagesFilter,
    getPrivateTokenPricepointsFromFilterValue,
    getRegionCategoryFilter, getSpyTokenPricepointsFromFilterValue,
    isLanguageFilterActive,
    isOfflineFollowed,
    isRoomSizeFilteringActive,
    KEYWORDS_LENGTH_LIMIT,
    LegacyAgeCategoryToFilterRange,
    PRIVATE_PRICES_FILTER_KEY,
    PrivatePriceCategoryToFilterValues,
    PrivatePrices,
    QueryParams,
    shouldShowHomepageFilters,
    ShowType, SpyShowPrices,
} from "@multimediallc/cb-roomlist-prefetch"
import { Gender, getCurrentGender } from "@multimediallc/gender-utils";
import { isSpyPriceFiltersActive } from "../../../../common/featureFlagUtil";
import type { IRoomListAPIParams } from "@multimediallc/cb-roomlist-prefetch"

/**
 * For use with LazyLoadedRoomList. Returns an IRoomListAPIParams that contains all the
 * filter values that are user-editable for the current pageload, e.g. via advanced search options
 */
export function getRoomlistDynamicFilters(searchVal?: string): IRoomListAPIParams {
    return {
        ...getVisibleGenderFilters(),
        ...getRegionsUserPrefsFilters(),
        ...getSearchInputFilters(searchVal),
        ...getFiltersPanelRoomlistFilters(),
    }
}

function getSearchInputFilters(searchVal?: string): IRoomListAPIParams {
    const apiFilters: IRoomListAPIParams = {}
    if (searchVal === undefined) {
        searchVal = (new URLSearchParams(window.location.search)).get("keywords") ?? ""
    }
    if (searchVal.length > 0) {
        apiFilters["keywords"] = searchVal.slice(0, KEYWORDS_LENGTH_LIMIT)
    }
    return apiFilters
}

export function getRegionFilterFromUserPrefs(): IRoomListAPIParams {
    const apiFilters: IRoomListAPIParams = {}
    const advancedSearchRegions = document.getElementById("filter_location_form")
    if (advancedSearchRegions !== null) {
        // Prevent ASO regions from applying filters if homepage filters are active and currently viewing the front page.
        const allRegions = advancedSearchRegions.querySelectorAll<HTMLInputElement>("input[type=checkbox]")
        const checkedRegions = advancedSearchRegions.querySelectorAll<HTMLInputElement>("input[type=checkbox]:checked")
        // IFF we have some but not all regions selected, convert them into a comma-separated
        // string and explicitly specify them in the API filter options
        if (allRegions.length !== checkedRegions.length && checkedRegions.length > 0) {
            apiFilters["regions"] = Array.from(checkedRegions).map(el => el.value).join(",")
        }
    }

    return apiFilters
}

function getVisibleGenderFilters(): IRoomListAPIParams {
    const apiFilters: IRoomListAPIParams = {}
    const genderString = getCurrentGender()

    if (genderString !== Gender.All) {
        apiFilters["genders"] = genderString
    }
    return apiFilters
}

/**
 Parses url to get query params for homepage filters.
 **/
function getFiltersPanelRoomlistFilters(): IRoomListAPIParams {
    const roomlistFilters: IRoomListAPIParams = {}
    const ageFilter = getParamsMinMaxAgeFilter()
    if (ageFilter !== undefined) {
        roomlistFilters.from_age = ageFilter[0]
        roomlistFilters.to_age = ageFilter[1]
    }
    const regionFilter = getParamsRegionFilters()
    if (regionFilter !== undefined) {
        roomlistFilters.regions = regionFilter
    }
    const privatePricesFilter = getPrivateTokenPricepointsFromParam()
    if (privatePricesFilter !== undefined) {
        roomlistFilters.private_prices = privatePricesFilter
    }
    if (isSpyPriceFiltersActive()) {
        const spyShowPricesFilter = getSpyTokenPricepointsFromParam()
        if (spyShowPricesFilter !== undefined) {
            roomlistFilters.spy_show_prices = spyShowPricesFilter
        }
    }
    if (isRoomSizeFilteringActive()) {
        const roomSizeFilter = getParamsRoomSizeFilter()
        if (roomSizeFilter !== undefined) {
            roomlistFilters.room_size = roomSizeFilter
        }
    }
    if (isLanguageFilterActive()) {
        const languageFilter = getParamsSpokenLanguagesFilter()
        if (languageFilter !== undefined) {
            roomlistFilters.spoken_languages = languageFilter
        }
    }
    return roomlistFilters
}

/**
 * Gets the private token price points from private price query param
 * @returns array of private price number
 */
function getPrivateTokenPricepointsFromParam(url?: URL): number[] | undefined {
    const searchString = url !== undefined ? url.search : window.location.search
    const queryParams = new URLSearchParams(searchString)
    const privatePricesVal = queryParams.get(PRIVATE_PRICES_FILTER_KEY)
    if (privatePricesVal !== null) {
        const privatePricesVals = privatePricesVal.split(",")
        // Filter only valid private price query parameters
        const privatePrices: PrivatePrices[] = privatePricesVals
            .filter(privatePrice => Object.values<string>(PrivatePrices).includes(privatePrice))
            .map(privatePrice => privatePrice as PrivatePrices)
        if (privatePrices.length > 0) {
            return getPrivateTokenPricepointsFromFilterValue(privatePrices)
        }
    }
}

/**
 * Gets the private token price points from private price query param
 * @returns array of private price number
 */
function getSpyTokenPricepointsFromParam(url?: URL): number[] | undefined {
    const searchString = url !== undefined ? url.search : window.location.search
    const queryParams = new URLSearchParams(searchString)
    const spyPricesVal = queryParams.get(QueryParams.spy_prices)
    if (spyPricesVal !== null) {
        const spyPricesVals = spyPricesVal.split(",")
        // Filter only valid private price query parameters
        const spyPrices: SpyShowPrices[] = spyPricesVals
            .filter(price => Object.values<string>(SpyShowPrices).includes(price))
            .map(price => price as SpyShowPrices)
        if (spyPrices.length > 0) {
            return getSpyTokenPricepointsFromFilterValue(spyPrices)
        }
    }
}

/**
 * Based on the page URL, creates a dict of all API category filters appropriate to the current
 * roomlist page. These are filters that remain static within a given page, including (but not
 * actually limited to) the default filters for any category page, such as the `NA` region on
 * /north-american-cams/ or the age range for /teen-cams/, but also includes pages that aren't
 * strictly considered cam "categories" by the backend, such as the app slug for /current_app_use/
 */
export function getRoomlistCategoryFilters(): IRoomListAPIParams {
    const apiFilters: IRoomListAPIParams = {}
    const region = getRegionCategoryFilter()
    if (region !== undefined) {
        apiFilters["regions"] = region
    }
    const ageRange = getAgeCategoryFilter()
    if (ageRange !== undefined) {
        [apiFilters["from_age"], apiFilters["to_age"]] = ageRange
    }
    const prices = getPriceCategoryFilter()
    if (prices !== undefined) {
        apiFilters["private_prices"] = getPrivateTokenPricepointsFromFilterValue([prices])
    }
    const appV1 = getAppsV1Slug()
    if (appV1 !== undefined) {
        apiFilters["apps"] = appV1
    }
    const appV2 = getAppsV2Slug()
    if (appV2 !== undefined) {
        apiFilters["asp_app"] = appV2
    }
    const camType = getCamTypeFilter()
    if (camType === ShowType.FOLLOW) {
        apiFilters["offline"] = isOfflineFollowed()
    }
    if (camType !== undefined) {
        apiFilters[camType] = true
    }
    const hashtag = getPageHashtag(true)
    if (hashtag !== undefined) {
        apiFilters["hashtags"] = hashtag
    }

    return apiFilters
}

/* ====== APP FILTER CATEGORIES ====== */

function getAppsV1Slug(): string | undefined {
    const APP_V1_BASE = "current_app_use/"
    const pathName = getCleanedPathname()
    if (!pathName.startsWith(APP_V1_BASE)) {
        return undefined
    }
    return pathName.slice(APP_V1_BASE.length).split("/")[0]
}

function getAppsV2Slug(): string | undefined {
    const APP_V2_BASE = "app/"
    const pathName = getCleanedPathname()
    if (!pathName.startsWith(APP_V2_BASE)) {
        return undefined
    }
    return pathName.slice(APP_V2_BASE.length).split("/")[0]
}

export function isCategoryPage(): boolean {
    const hasCategory = (filterVal: string | number[] | undefined) => (filterVal !== undefined)
    return [
        getRegionCategoryFilter(),
        getAgeCategoryFilter(),
        getPriceCategoryFilter(),
        getCamTypeCategoryFilter(),
    ].some(hasCategory)
}

/**
 * Determines the current page's age category range, if any, for use with the roomlist API.
 * @returns a pair of ints defining an age range, or undefined if not on an age category page
 */
function getAgeCategoryFilter(): [number, number] | undefined {
    const categorySlug = getCategorySlug()
    return AgeCategoryToFilterRange.get(categorySlug) ?? LegacyAgeCategoryToFilterRange.get(categorySlug)
}

/**
 * Determines the current page's private show price category, if any, for use with the roomlist API.
 * @returns a list of token-per-minute price values included in the category, or undefined
 */
function getPriceCategoryFilter(): PrivatePrices | undefined {
    const categorySlug = getCategorySlug()
    return PrivatePriceCategoryToFilterValues.get(categorySlug)
}

// NOTE this is only used in this file for the purpose of distinguishing "category pages" from
// other types of roomlist page, for the sake of actually getting a boolean flag to use with
// the roomlist API you want to use getCamTypeFilter() instead
function getCamTypeCategoryFilter(): ShowType | undefined {
    const categorySlug = getCategorySlug()
    return CamTypeCategoryToFilterKey.get(categorySlug)
}

export function getRegionsUserPrefsFilters(): IRoomListAPIParams {
    const apiFilters: IRoomListAPIParams = {}
    const advancedSearchRegions = document.getElementById("filter_location_form")
    if (advancedSearchRegions !== null && !shouldShowHomepageFilters()) {
        // Prevent ASO regions from applying filters if homepage filters are active and currently viewing the front page.
        const allRegions = advancedSearchRegions.querySelectorAll<HTMLInputElement>("input[type=checkbox]")
        const checkedRegions = advancedSearchRegions.querySelectorAll<HTMLInputElement>("input[type=checkbox]:checked")
        // IFF we have some but not all regions selected, convert them into a comma-separated
        // string and explicitly specify them in the API filter options
        if (allRegions.length !== checkedRegions.length && checkedRegions.length > 0) {
            apiFilters["regions"] = Array.from(checkedRegions).map(el => el.value).join(",")
        }
    }
    return apiFilters
}
