import { extractKeywords, getPageHashtag } from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"

interface INoRoomsAvailableState {
    numRooms: number
    filterPanelActive?: boolean
}

export class NoRoomsAvailableNotification extends HTMLComponent<HTMLParagraphElement, object, INoRoomsAvailableState> {
    constructor(props: object) {
        super(props)
    }

    protected createElement(props: object): HTMLParagraphElement {
        return (
            <p className="no-rooms-available" data-testid="no-rooms-available" bind={ {
                text: () => this.messageString(),
                display: () => {
                    if (this.state.filterPanelActive === true) {
                        return "none"
                    }
                    return this.state.numRooms === 0 ? "" : "none"
                },
            } }></p>
        )
    }

    private messageString(): string {
        const searchString = this.searchString()
        return searchString.length > 0 ? i18n.noSearchResultsMatch(searchString) : i18n.noRoomsAvailableFiltered
    }

    private searchString() {
        const pageTag = getPageHashtag()
        const [ keywords, keywordHashtags ] = extractKeywords()
        if (pageTag !== undefined) {
            keywordHashtags.push(pageTag)
        }
        return [ ...keywords, ...keywordHashtags.map((tag) => `#${ tag }`) ].join(" ")
    }
}
