import { HTMLComponent } from "../../../../common/defui/htmlComponent"
import { isFilterInPathActive } from "../../../../common/featureFlagUtil"
import { addPageAction } from "../../../../common/newrelic"
import { dom } from "../../../../common/tsxrender/dom"
import { ReactWrapper } from "../../ReactWrapper"


export interface FilterLabelProps {
    title: string,
    onRemoval: () => void,
}

export class FilterLabel extends HTMLComponent<HTMLDivElement, FilterLabelProps> {
    protected props: FilterLabelProps

    protected initUI(props: FilterLabelProps): void {
        super.initUI(props)
        if (!isFilterInPathActive()) {
            const appended = this.addToLabelSection()
            if (!appended) {
                // retry if label section not present
                window.setTimeout(() => {
                    this.addToLabelSection()
                }, 250)
            }
        }
    }

    protected createElement(props: FilterLabelProps): HTMLDivElement {
        return <div className="filterLabel" data-testid="filter-label-remove" data-ishashtag={props.title.startsWith("#")}>
            { props.title }
            <div onClick={() => { this.removeFilter(props) }} className="filterLabelRemove" >
                <ReactWrapper
                    component="RemoveFilterIcon"
                    componentProps={{}}
                />
            </div>
        </div>
    }

    protected initData(props: FilterLabelProps): void {
        super.initData(props)
        this.props = props
    }

    private removeFilter(props: FilterLabelProps): void {
        this.element.remove()
        addPageAction("HmpgFilterLabelClicked", {
            "title": this.props.title,
        })
        props.onRemoval()
    }

    private addToLabelSection(): boolean {
        // The first element can either be the clear all button or a filter label.
        // If the first element of the label section is not a tag filter label, prepend this label.
        // Otherwise, add it in second postion after the tag label.

        const labelSection = document.body.querySelector("div[class=filterLabelSection]")
        if (labelSection !== null) {
            const firstChild = labelSection.firstElementChild
            if (firstChild instanceof (HTMLDivElement) && firstChild.dataset["ishashtag"] === "true") {
                const secondElement = labelSection.children[1]
                labelSection.insertBefore(this.element, secondElement)
            } else {
                labelSection.prepend(this.element)
            }
            return true
        }
        return false
    }

    public getTitle(): string {
        return this.props.title
    }
}
