import {
    ALL_NO_PAGE,
    getFilterPanelOpenCached,
    homepageFiltersCurrentlyApplied,
    shouldShowHomepageFilters,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../../common/defui/htmlComponent"
import { EventRouter } from "../../../../common/events"
import { isFilterInPathActive } from "../../../../common/featureFlagUtil"
import { addPageAction } from "../../../../common/newrelic"
import { i18n } from "../../../../common/translation"
import { dom } from "../../../../common/tsxrender/dom"
import { ReactWrapper } from "../../ReactWrapper"
import { setFilterPanelOpenCached } from "./homepageFiltersUtil"
import type { HomepageFilterPanel } from "./filterPanel"

interface IFilterButtonProps {
    filterPanel?: HomepageFilterPanel
}

interface FilterButtonState {
    isActive: boolean
    hasSelectedFilters: boolean
}

export class HomepageFilterButton extends HTMLComponent<HTMLDivElement, IFilterButtonProps, FilterButtonState> {
    private filtersIconWrapper: ReactWrapper
    private filterPanel?: HomepageFilterPanel
    public static filterPanelOpen = new EventRouter<boolean>("filterPanelOpen")

    protected createElement(): HTMLDivElement {
        this.filtersIconWrapper = new ReactWrapper({
            component: "HomepageFiltersIcon",
            componentProps: {
                hasSelectedFilters: this.state.hasSelectedFilters,
            },
        })

        return <div
            onClick={() => this.handleClick()}
            bind={{ className: () => `homepageFilterButton ${this.state.isActive ? "active" : ""}`,
                display: () => shouldShowHomepageFilters() ? "block" : "none" }}
            data-testid="filter-button"
        >
            <div className="homepageFiltersIconRoot">
                {this.filtersIconWrapper.element}
            </div>
            <div colorClass="text" data-testid="filter-button-text">{i18n.filters}</div>
        </div>
    }

    protected initData(props: IFilterButtonProps): void {
        super.initData(props)

        UrlState.current.listen(ALL_NO_PAGE, () => {
            this.setState({ ...this.state, hasSelectedFilters: homepageFiltersCurrentlyApplied() })
        }, this.element)
        this.filterPanel = props.filterPanel
        this.setState({ hasSelectedFilters: homepageFiltersCurrentlyApplied(), isActive: getFilterPanelOpenCached() })
    }

    protected initUI(props?: IFilterButtonProps): void {
        super.initUI(props)
        if (isFilterInPathActive()) {
            HomepageFilterButton.filterPanelOpen.listen((isOpen) => {
                this.setState({ ...this.state, isActive: isOpen })
            })
        }
    }

    setActive(active: boolean): void {
        this.setState({ ...this.state, isActive: active })
        this.filterPanel?.setState({ isOpen: this.state.isActive })
        setFilterPanelOpenCached(active)
    }

    updateState(): void {
        super.updateState()
        this.filtersIconWrapper.update({ hasSelectedFilters: this.state.hasSelectedFilters })
        if (isFilterInPathActive()) {
            setFilterPanelOpenCached(this.state.isActive)
        }
    }

    private handleClick(): void {
        if (isFilterInPathActive()) {
            this.setState({ ...this.state, isActive: !this.state.isActive })
        } else {
            this.setActive(!this.state.isActive)
        }
        HomepageFilterButton.filterPanelOpen.fire(this.state.isActive)
        addPageAction("HmpgFilterPanelMovement", {
            "open": this.state.isActive,
        })
    }
}
