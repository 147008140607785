import { normalizeResource } from "../../common/api"
import { Gender, GendersSymbolToNameMap, getVerboseGenderPath } from "../../common/genders"

export function hashtagUrl(hashtag: string, gender: Gender): string {
    if (gender === Gender.All || hashtag === "") {
        return normalizeResource(`/tag/${hashtag}/`)
    }
    return normalizeResource(`/tag/${hashtag}/${GendersSymbolToNameMap.get(gender)}/`)
}

export function hashtagsUrl(gender: Gender): string {
    const url = `/tags/${gender !== Gender.All ? `${GendersSymbolToNameMap.get(gender)}/` : ""}`
    return normalizeResource(url)
}

export function genderedHomepageUrl(gender: Gender): string {
    const verboseGender = getVerboseGenderPath(gender)
    const url = verboseGender === "" ? "/" : `/${verboseGender}/`
    return normalizeResource(url)
}
