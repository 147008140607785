import { Gender } from "@multimediallc/gender-utils"
import { ArgJSONMap } from "@multimediallc/web-utils"
import { getCb } from "../../../../common/api"
import { Debouncer, DebounceTypes } from "../../../../common/debouncer"
import { HTMLComponent } from "../../../../common/defui/htmlComponent"
import { isAlphaNumericKey, isArrowDownKey, isArrowUpKey, isEnterKey } from "../../../../common/eventsUtil"
import { isFilterInPathActive } from "../../../../common/featureFlagUtil"
import { addPageAction } from "../../../../common/newrelic"
import { i18n } from "../../../../common/translation"
import { dom } from "../../../../common/tsxrender/dom"
import { ReactComponentRegistry } from "../../ReactRegistry"
import { getGenderForTagsApi } from "./homepageFiltersUtil"
import { TagAutocompleteList } from "./tagAutocompleteList"

interface TagSearchProps {
    applyTagFilter: (tag: string) => void,
}

const AUTOCOMPLETE_DEBOUNCE_DELAY = 250
const MAX_INPUT_LENGTH = 50

export class TagSearch extends HTMLComponent<HTMLDivElement, TagSearchProps> {
    private searchInput: HTMLInputElement
    private suggestionsList: TagAutocompleteList
    private props: TagSearchProps
    private autocompleteDebouncer: Debouncer
    private loading = false
    private onlineTagsMatchingFilters: Set<string>

    protected createElement(props: TagSearchProps): HTMLDivElement {
        this.onlineTagsMatchingFilters = new Set()
        const SearchIcon = ReactComponentRegistry.get("Search")
        const searchIconRoot = <div className="searchIcon" />
        new SearchIcon({}, searchIconRoot)
        return <div className="tagSearchDiv">
            <input className="tagSearchInput"
                   data-testid="filter-tag-search-input"
                   placeholder={i18n.tagSearchPlaceholder}
                   ref={(el: HTMLInputElement) => {this.searchInput = el}}
                   maxLength={MAX_INPUT_LENGTH}
                   onFocus={() => {
                       this.callAutocompleteDebouncer()
                       addPageAction("TagSearchFocus")
                   }}
                   onBlur={() => {this.suggestionsList.hideElement()}}
                   onKeyDown={(e: KeyboardEvent) => {
                       this.handleSpecialKey(e)
                       this.cleanNewChar(e)
                   }}
                   onInput={() => {
                       this.cleanSearchInput()
                       this.callAutocompleteDebouncer()
                   }}
            />
            <div className="searchButton" data-testid="filter-tag-search-button" onClick={() => {
                this.submitSearch("click")
            }}>
                {searchIconRoot}
            </div>
            <TagAutocompleteList
                classRef={(c: TagAutocompleteList) => {this.suggestionsList = c}}
                onTagClick={(tag: string) => {
                    addPageAction("TagAutocompleteClick", {
                        "tagClicked": tag,
                        "searchText": this.getSearchWord(),
                    })
                    this.applySearchTag(tag)
                }}
            />
        </div>
    }

    protected initData(props: TagSearchProps): void {
        this.props = props
        this.autocompleteDebouncer = new Debouncer(() => {
            this.updateAutocomplete()
        }, { bounceLimitMS: AUTOCOMPLETE_DEBOUNCE_DELAY, debounceType: DebounceTypes.debounce })
    }

    protected initUI(): void {
        if (!isFilterInPathActive()) {
            this.loadKeywordResults("", false)
        }
    }

    public updateOnlineTags(onlineTagsMatchingFilters: string[]): void {
        this.onlineTagsMatchingFilters = new Set(onlineTagsMatchingFilters)
    }

    private loadKeywordResults(keyword: string, show = true): void {
        void this.getKeywordResults(keyword).then((tagNames: string[]) => {
            this.suggestionsList.updateResults(tagNames, keyword)
            if (show && document.activeElement === this.searchInput) {
                this.suggestionsList.showElement()
            }
        }).finally(() => {
            this.loading = false
        })
    }

    private getKeywordResults(keyword: string): Promise<string[]> {
        return getCb(this.buildTagSearchApiUrl(keyword)).then((xhr: XMLHttpRequest) => {
            const responseMap = new ArgJSONMap(xhr.responseText)
            const tagsData = responseMap.getList("tags") ?? []
            const tagsList = tagsData.map(data => data.getString("hashtag"))
            return tagsList.filter((tag: string) => this.onlineTagsMatchingFilters.has(tag))
        })
    }

    // Prevent invalid characters from being typed into search input
    private cleanNewChar(e: KeyboardEvent): void {
        // Allow # key if it's the 1st char in the search input and no other # key exists
        if (e.key === "#" && this.searchInput.selectionStart === 0 && this.searchInput.value.indexOf("#") === -1) {
            return
        }
        // Allow hyphens
        if (e.key === "-") {
            return
        }
        // Prevent non-alphanumeric characters
        if (e.key.length === 1 && !isAlphaNumericKey(e)) {
            e.preventDefault()
        }
    }

    // Removes invalid characters that have been pasted into searchInput and bypassed cleaning in keydown handler
    private cleanSearchInput(): void {
        // Allow characters that are: alphanumeric, -, #
        const cleanValue = this.searchInput.value.replace(/[^a-zA-Z0-9#-]+/g,"")
        // Only allow # character to be at the beginning, remove otherwise.
        const noHashtagValue = cleanValue.replace(/#/g, "").substring(0, MAX_INPUT_LENGTH)
        const prependChar = cleanValue[0] === "#" ? "#" : ""
        const correctValue = `${prependChar}${noHashtagValue}`
        if (correctValue !== this.searchInput.value) {
            this.searchInput.value = correctValue
        }
    }

    private getSearchWord(): string {
        return this.searchInput.value.replace(/#/g, "").toLowerCase()
    }

    private buildTagSearchApiUrl(keyword: string): string {
        const urlQuery = new URLSearchParams([
            ["field", "room_count"],
            ["search", keyword],
        ])
        const gender = getGenderForTagsApi()
        if (gender !== Gender.All) {
            urlQuery.append("genders", gender)
        }
        return `api/ts/hashtags/search_get_data/?${urlQuery.toString()}`
    }

    private updateAutocomplete(): void {
        this.loadKeywordResults(this.getSearchWord())
    }

    private callAutocompleteDebouncer(): void {
        // loading is true while waiting for the autocomplete debounce time and tag search response
        this.loading = true
        this.autocompleteDebouncer.callFunc()
    }

    // Special keys are: up/down arrow keys, "Enter" key
    private handleSpecialKey(e: KeyboardEvent): void {
        if (!this.loading && (isArrowUpKey(e) || isArrowDownKey(e))) {
            e.preventDefault()
            this.suggestionsList.highlightViaKeyNav(e)
            const highlightedTag = this.suggestionsList.getHighlightedText()
            if (highlightedTag !== undefined) {
                this.searchInput.value = highlightedTag
            }
        } else if (isEnterKey(e)) {
            this.submitSearch("enter")
        }
    }

    // User isn't allowed to submit just anything as a tag filter, because we need to send user to an existing tag page.
    // Therefore, we check that user's input exists in autocomplete list, before applying it as a tag filter.
    private submitSearch(submitMethod: "click"|"enter"): void {
        const searchText = this.getSearchWord()
        if (searchText === "" || !this.suggestionsList.isTagValid(searchText)) {
            addPageAction("TagSearchAttempt", {
                "searchText": searchText,
            })
            return
        }

        this.applySearchTag(searchText, submitMethod)
    }

    // Apply tag filter to page, reset tag search UI to show search has been submitted
    private applySearchTag(tag: string, submitMethod?: string): void {
        if (submitMethod !== undefined) {
            addPageAction("TagSearchSubmit", {
                "tag": tag,
                "submitMethod": submitMethod,  // "enter" or "click"
            })
        }

        if (tag !== "") {
            this.props.applyTagFilter(tag)
            this.searchInput.value = ""
        }
        this.searchInput.blur()
        this.suggestionsList.unhighlightList()
    }
}
