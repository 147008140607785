import { ALL, homepageFiltersCurrentlyApplied, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../../common/defui/htmlComponent"
import { isFilterInPathActive } from "../../../../common/featureFlagUtil";
import { i18n } from "../../../../common/translation"
import { dom } from "../../../../common/tsxrender/dom"

interface FiltersClearButtonProps {
    onClearAll: () => void
}

export class FiltersClearButton extends HTMLComponent<HTMLDivElement, FiltersClearButtonProps> {

    protected createElement(props: FiltersClearButtonProps): HTMLDivElement {
        return <div
            onClick={() => { this.clearAll(props) }}
            className="filtersClearButton"
            data-testid="filter-clear-button"
        >
            <div colorClass="text">{i18n.clear}</div>
        </div>
    }

    private clearAll(props: FiltersClearButtonProps): void {
        props.onClearAll()
    }

    protected initData(props: FiltersClearButtonProps): void {
        super.initData(props)
        this.showOrHideElement(homepageFiltersCurrentlyApplied(), "inline-block")
        if (!isFilterInPathActive()) {
            UrlState.current.listen(ALL, () => {
                this.showOrHideElement(homepageFiltersCurrentlyApplied(), "inline-block")
            }, this.element)
        }
    }
}
