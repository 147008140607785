import {
    getAPIParamsFromURLState,
    getFilterPanelOpenCached,
    getNonReservedQueryParamValues,
    getPageHashtag,
    HOMEPAGE_KEYS_NO_PAGE,
    PageType,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { Gender } from "@multimediallc/gender-utils"
import { ArgJSONMap } from "@multimediallc/web-utils"
import { getCb } from "../../../../../common/api"
import { HTMLComponent } from "../../../../../common/defui/htmlComponent"
import { isFilterInPathActive } from "../../../../../common/featureFlagUtil"
import { getCurrentGender, getVerboseGenderPath } from "../../../../../common/genders"
import { addPageAction } from "../../../../../common/newrelic"
import { printCatch } from "../../../../../common/promiseUtils";
import { i18n } from "../../../../../common/translation"
import { dom } from "../../../../../common/tsxrender/dom"
import { resizeDebounceEvent } from "../../../../ui/responsiveUtil"
import { hashtagUrl } from "../../../../util/hashtagsUtils"
import { ReactComponentRegistry } from "../../../ReactRegistry"
import { HomepageFilterButton } from "../filterButton"
import { FilterOption } from "../filterOption"
import { getRoomlistCategoryFilters, getRoomlistDynamicFilters } from "../filtersUtil"
import { getGenderForTagsApi } from "../homepageFiltersUtil"
import { TagSearch } from "../tagSearch"
import type { ReactComponent } from "../../../ReactRegistry"
import type { IRoomListAPIParams } from "@multimediallc/cb-roomlist-prefetch"


interface TagSectionProps {
    onFilterOptionClick: () => void,
}

interface TagSectionState {
    isLoading: boolean
    isVisible: boolean
    tagPageNum: number
    maxPageNum: number
}

const TOP_TAGS_FETCH_COUNT = 1000
const MAX_ROWS_TAG_OPTIONS = 13
const ROOMLIST_ALL_TAGS_API_URL = "api/ts/roomlist/all-tags/"

export class TagSection extends HTMLComponent<HTMLDivElement, TagSectionProps, TagSectionState> {
    private currentPageTagsList: string[]
    private optionsContainer: HTMLDivElement
    private tagSearch: TagSearch
    private topTagsList: string[]
    private props: TagSectionProps
    private pageMinIndices: number[]
    private tagPagination?: ReactComponent
    private onlineTagsMatchingFilters: string[]  // The set of hashtags matching current roomlist filters.
    // It is set by loadOnlineTopTags(), and is not necessarily equal to this.onlineTopTagsFiltered due to score sorting
    private onlineTopTagsFiltered: string[]  // The score-sorted top-tags results that have rooms online matching the current roomlist filters
    private tagPaginationRoot: HTMLDivElement

    protected createElement(): HTMLDivElement {
        this.state.isLoading = true
        this.state.isVisible = getFilterPanelOpenCached()
        this.pageMinIndices = [0]

        const TagPagination = ReactComponentRegistry.get("TagPagination")
        this.tagPaginationRoot = <div className="tagPaginationRoot"></div>
        this.tagPagination = new TagPagination({
            isDisabled: this.state.isLoading,
            currentPage: 1,
            maxPage: 1,
            onNextPageClick: (e: MouseEvent | KeyboardEvent): void => {
                if (e instanceof MouseEvent && (e.ctrlKey || e.metaKey || e.shiftKey) || this.state.isLoading) {
                    return
                }
                this.updatePageNum(this.state.tagPageNum !== this.state.maxPageNum ? this.state.tagPageNum + 1 : 1)
                this.renderTagsPage()
            },
            onPrevPageClick: (e: MouseEvent | KeyboardEvent): void => {
                if (e instanceof MouseEvent && (e.ctrlKey || e.metaKey || e.shiftKey) || this.state.isLoading) {
                    return
                }
                this.updatePageNum(this.state.tagPageNum !== 1 ? this.state.tagPageNum - 1 : this.state.maxPageNum)
                this.renderTagsPage()
            },
        }, this.tagPaginationRoot)

        this.tagSearch = new TagSearch({
            applyTagFilter: (tag: string) => {
                // Noop when tag is already selected
                if (getPageHashtag(true) === tag) {
                    return
                }
                if (isFilterInPathActive()) {
                    UrlState.current.setPartialState({ tags: [tag]})
                } else {
                    this.toggleTagInURL(tag)  // trigger the urlstate listener to update the UI
                }
                this.props.onFilterOptionClick()
            },
        })

        return <div
            bind={{
                className: () => `${this.state.maxPageNum > 1 ? "multiPage " : ""}paginated tagSection filterSection`,
            }}
            data-testid="filter-tag-section">
            <div className="filterSectionHeader" data-testid="filter-tag-header">{i18n.tagsCAPS}</div>
            {this.tagSearch.element}
            <div
                ref={(el: HTMLDivElement) => { this.optionsContainer = el }}
                bind={{
                    className: () => `filterSectionOptions tagSectionOptions ${this.state.isLoading && this.state.maxPageNum > 1 ? "loading" : ""}`,
                }}
            ></div>
            {this.tagPaginationRoot}
        </div>
    }

    protected initUI(): void {
        resizeDebounceEvent.addListener(() => {
            this.renderTagsPage(true)
        }, this)
        HomepageFilterButton.filterPanelOpen.listen((isOpen: boolean) => {
            this.setState({ ...this.state, isVisible: isOpen })
            if (isOpen) {
                window.setTimeout(() => this.renderTagsPage(true))
            }
        })
    }

    protected initData(props: TagSectionProps): void {
        super.initData(props)

        UrlState.current.listen(HOMEPAGE_KEYS_NO_PAGE, () => {
            this.updateTopTagsLists(false).then(() => this.renderTagsPage()).catch(printCatch)
        }, this.element)

        // fetch top tags AND all roomlist-filtered-tags when gender changes
        UrlState.current.listen([
            "genders", "pageType", "showType",
        ], () => {
            if (!isFilterInPathActive() || UrlState.current.state.pageType === PageType.HOME) {
                this.updateTopTagsLists(true).then(() => this.renderTagsPage()).catch(printCatch)
            }
        }, this.element)

        // only change tag option selection state and order of current tags page when tag selection changes
        UrlState.current.listen(["tags"], () => { this.renderTagsPage() }, this.element)
        this.props = props
        this.topTagsList = []
        this.onlineTopTagsFiltered = []
        void this.updateTopTagsLists(true).then(() => this.renderTagsPage())
    }

    updateState(): void {
        super.updateState()
        if (this.tagPagination !== undefined) {
            // add or remove the pagination component if the current filters selection yield 2 or more pages
            // of tag options
            if (this.state.maxPageNum <= 1 && this.element.contains(this.tagPaginationRoot)) {
                this.element.removeChild(this.tagPaginationRoot)
            } else if (this.state.maxPageNum > 1) {
                this.element.appendChild(this.tagPaginationRoot)
            }
            this.tagPagination.update({ currentPage: this.state.tagPageNum, maxPage: this.state.maxPageNum, isDisabled: this.state.isLoading })
        }
    }

    private updatePageNum(pageNum: number): void {
        this.setState({ ...this.state, tagPageNum: pageNum })
    }

    private updateMaxPageNum(responsivePaging: boolean): void {
        this.setPagingData(responsivePaging)
        this.setState({ ...this.state, maxPageNum: this.state.maxPageNum })
    }

    private async updateTopTagsLists(fetchTopTags: boolean): Promise<void> {
        // Loads all unique tags matching current filter set, and optionally loads the
        // top tags list depending on boolean argument fetchTopTags.  The top tags list
        // only varies with gender, whereas the current filter set encompasses gender and
        // other filters.
        this.setState({ ...this.state, tagPageNum: 1, isLoading: true })

        const tagLoads: Promise<void>[] = [this.loadOnlineTopTags()]
        if (fetchTopTags) {
            tagLoads.push(this.loadTopTagsList())
        }
        await Promise.all(tagLoads)
        this.onlineTopTagsFiltered = this.topTagsList.filter((tag) => this.onlineTagsMatchingFilters.includes(tag))

        this.setState({ ...this.state, isLoading: false })
    }

    private renderTagsPage(responsivePaging = false): void {
        // Adds all tag options that should go on the current page. The current page tag should always be visible,
        // so it is added to the page if not present
        if (this.state.isLoading || !getFilterPanelOpenCached()) {
            return
        }
        this.optionsContainer.textContent = ""
        this.updateMaxPageNum(responsivePaging)
        this.createTagOptionsForPage()
        this.addCurrentTagToOptionsIfNotPresent(getPageHashtag(true))
        this.updateTagSearch()
    }

    private createTagOptionsForPage(): void {
        // Adds all tag options that fit on this page, which were determined by this.setPagingData()
        this.currentPageTagsList = []
        const [start, stop] = [this.pageMinIndices[this.state.tagPageNum - 1], this.pageMinIndices[this.state.tagPageNum]]

        for (const tag of this.onlineTopTagsFiltered.slice(start, stop)) {
            this.optionsContainer.append(this.createTagOptionElement(tag))
            this.currentPageTagsList.push(tag)
        }
    }

    private updateTagSearch(): void {
        // Hides the tag search if there are fewer than 2 pages of results.  Otherwise, restricts
        // the set of tags that can be suggested to those present in the current filtered roomlist
        const filteredTagsList = this.onlineTopTagsFiltered
        if (this.state.maxPageNum < 2) {
            this.tagSearch.hideElement()
        } else {
            this.tagSearch.updateOnlineTags(filteredTagsList)
            this.tagSearch.showElement("inline-block")
        }
    }

    private addCurrentTagToOptionsIfNotPresent(currentTag: string | undefined): void {
        // Puts current page tag at the front of the tag options container if it is not already in it.
        // If the options container height changes after adding the current tag, removes tags from the
        // current page until the container returns to the target height or the current tag is the only one left.
        if (currentTag === undefined || this.currentPageTagsList.includes(currentTag)) {
            return
        }

        const targetHeight = this.optionsContainer.offsetHeight
        this.optionsContainer.prepend(this.createTagOptionElement(currentTag))
        this.currentPageTagsList.push(currentTag)
        this.adjustOptionsContainerHeight(targetHeight)
    }

    private adjustOptionsContainerHeight(targetHeight: number): void {
        while (this.optionsContainer.offsetHeight > targetHeight && this.currentPageTagsList.length > 1) {
            this.optionsContainer.lastElementChild?.remove()
        }
    }

    /**
     * Calculates and sets pagination data for tag options.
     *
     * This function determines the maximum number of pages (`maxPageNum`) needed to display all
     * tag options within the allowed number of rows (`MAX_ROWS_TAG_OPTIONS`) per page. It computes
     * the starting indices (`pageMinIndices`) of each page based on the `offsetTop` positions of
     * the tag options.
     *
     * If `setMatchingPageNumber` is true, it adjusts the current page number proportionally to
     * match the new maximum page number.  This is used to respond to screen width resizing.
     *
     * The function optimizes performance by:
     * - Rendering all tag options at once using a `DocumentFragment` to minimize reflows.
     * - Calculating `offsetTop` values after rendering to determine row positions without extra reflows.
     * - Clearing the `optionsContainer` after calculations to remove temporary elements from the DOM.
     *
     * @param {boolean} setMatchingPageNumber - Indicates whether to adjust the current page number to match the new pagination.
     */
    private setPagingData(setMatchingPageNumber: boolean): void {
        const virtualContainer = document.createDocumentFragment()
        for (const tag of this.onlineTopTagsFiltered) {
            virtualContainer.appendChild(this.createTagOptionElement(tag))
        }
        this.optionsContainer.appendChild(virtualContainer)

        const allHeights = new Set<number>()
        const tagOptions = this.optionsContainer.children
        this.pageMinIndices = [0]
        for (let idx = 0; idx < tagOptions.length; idx++) {
            const tagOption = tagOptions[idx] as HTMLAnchorElement
            allHeights.add(tagOption.offsetTop)
            if ((allHeights.size) > MAX_ROWS_TAG_OPTIONS) {
                this.pageMinIndices.push(idx)
                allHeights.clear()
                allHeights.add(tagOption.offsetTop)
            }
        }

        const newMaxPageNum = this.pageMinIndices.length
        // prevent on initial page load
        if (setMatchingPageNumber && this.state.maxPageNum !== undefined) {
            this.state.tagPageNum = Math.min(1 + Math.round(newMaxPageNum * (this.state.tagPageNum - 1) / this.state.maxPageNum), newMaxPageNum)
        }
        this.pageMinIndices.push(tagOptions.length)
        this.state.maxPageNum = newMaxPageNum
        this.optionsContainer.textContent = ""
    }

    private async loadTopTagsList(): Promise<void> {
        // Fetches the top tags list for the current user and saves it to this.topTagsList
        const urlQuery = new URLSearchParams([["count", String(TOP_TAGS_FETCH_COUNT)]])
        const gender = getGenderForTagsApi()
        if (gender !== Gender.All) {
            urlQuery.append("genders", gender)
        }
        const xhr = await getCb(`api/ts/hashtags/top_tags/?${urlQuery.toString()}`)
        const json = new ArgJSONMap(xhr.responseText)
        this.topTagsList = json.getStringList("all_tags")
    }

    private getCurrentFilters(): IRoomListAPIParams {
        // Gets the set of currently active roomlist filters.  These filters will be passed to the all-tags
        // api to get the list of tags with non-empty roomlists matching current filters.  Hashtag filter state is
        // removed since the all-tags endpoint will return all tags that have rooms in the current filter set
        const newCategoryFilters = getRoomlistCategoryFilters()
        const newFilters = getRoomlistDynamicFilters()
        const filters = { ...newFilters, ...newCategoryFilters }
        delete filters["hashtags"]
        delete filters["offset"]
        delete filters["limit"]
        return filters
    }

    private async loadOnlineTopTags(): Promise<void> {
        // Fetches all unique hashtags with the rooms matching current roomlist filters
        const filters = isFilterInPathActive() ? getAPIParamsFromURLState(UrlState.current.state) : this.getCurrentFilters()
        const minTagsVal = (new URLSearchParams(window.location.search)).get("min_tags_count")
        const minTags = (minTagsVal !== null && parseInt(minTagsVal) > 0) ? minTagsVal : "1"
        const queryParams = new URLSearchParams({
            ...getNonReservedQueryParamValues(),
            min_count: minTags,
            ...filters as Record<string, string>,
        })
        queryParams.delete("min_tags_count")

        queryParams.sort()  // Sort params to ensure request URLs with identical filters correspond 1:1
        const fetchUrl = `${ROOMLIST_ALL_TAGS_API_URL}?${queryParams.toString()}`
        const xhr = await getCb(fetchUrl)
        const jsonMap = new ArgJSONMap(xhr.responseText)
        this.onlineTagsMatchingFilters = jsonMap.getStringList("all_tags")
    }

    private createTagOptionElement(tag: string): HTMLAnchorElement {
        // Returns a new filter option element for the provided hashtag
        const filterOption = new FilterOption({
            testid: "filter-tag-item",
            name: tag,
            labelText: `#${tag}`,
            queryParamValue: tag,
            getHref: () => this.getToggledTagUrl(tag).href,
            optionIsActive: () => this.optionIsActive(tag),
            handleLeftClick: () => this.handleLeftClick(tag),
        })
        return filterOption.element
    }

    private handleLeftClick(queryParamValue: string): void {
        if (!isFilterInPathActive()) {
            this.toggleTagInURL(queryParamValue)  // trigger the urlstate listener to update the UI
        } else {
            if ((UrlState.current.state.tags ?? []).includes(queryParamValue)) {
                UrlState.current.clearStateKeys(["tags", "page", "pageb"])
            } else {
                UrlState.current.setPartialState({ tags: [queryParamValue]})
            }
        }
        this.props.onFilterOptionClick()
        addPageAction("HmpgFilterOptionClicked", {
            "category": "tags",
            "value": queryParamValue,
            "active": this.optionIsActive(queryParamValue),
        })
    }

    private optionIsActive(queryParamValue: string): boolean {
        // This method is passed to tag filter options as a prop
        const currentValue = UrlState.current.state["tags"]
        if (currentValue === undefined) {
            return false
        }
        return currentValue.includes(queryParamValue)
    }

    private toggleTagInURL(tag: string): void {
        const newUrl = this.getToggledTagUrl(tag)
        UrlState.current.pushUrl(newUrl)
    }

    private getToggledTagUrl(tag: string): URL {
        if (tag === undefined || tag === getPageHashtag(true)) {
            const newUrl = new URL(`${window.location.origin}/${getVerboseGenderPath(getCurrentGender())}`)
            newUrl.search = window.location.search
            return newUrl
        } else {
            const path = hashtagUrl(tag, getCurrentGender())
            const newUrl = new URL(window.location.origin + path)
            newUrl.search = window.location.search
            return newUrl
        }
    }
}
