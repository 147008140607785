import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"

export class RoomReloadErrorNotification extends HTMLComponent<HTMLDivElement> {
    protected createElement(): HTMLDivElement{
        const reloadPage = () => {
            window.location.reload()
        }
        const errorMessage = <p className="roomlist-reload-msg"/>
        // eslint-disable-next-line @multimediallc/no-inner-html
        errorMessage.innerHTML = i18n.unableToReloadErrorMessage
        errorMessage.firstElementChild.onclick = reloadPage
        return errorMessage
    }

    public showElement(): void {
        super.showElement("inline-block")
    }
}
