import {
    getNonReservedQueryParamValues, getPaginationAPIParams, type IRoomListAPIParams,
} from "@multimediallc/cb-roomlist-prefetch"
import { ArgJSONMap } from "@multimediallc/web-utils"
import { getCb } from "../../../common/api"
import { isFilterInPathActive } from "../../../common/featureFlagUtil"
import { parseIRoomInfo } from "./roomCard"
import type { IRoomInfo } from "./IRoomInfo"

const FETCH_RETRY_LIMIT = 5

interface IRoomFetchResult {
    loadedRooms: IRoomInfo[]
    matchedCount: number
    totalCount: number
    fetchId: number  // Used for resolving potential race conditions
    roomListId?: string
}

interface IApiRoomsIteratorOptions {
    apiUrl: string
    currentPage?: number
    pageSize?: number
}

export class PaginatedApiRoomsIterator {
    private filters: IRoomListAPIParams
    private apiUrl: string
    private currentPage?: number
    private pageSize?: number

    constructor(options: IApiRoomsIteratorOptions) {
        this.apiUrl = options.apiUrl
        this.currentPage = options.currentPage
        this.pageSize = options.pageSize
    }

    /**
     * Sets the filters that the rooms iterator should use for its API requests. Should include both dynamic
     * and category filters (if applicable) as the iterator doesn't distinguish between them.
     * @param newFilters An object containing the COMPLETE filter values to use, except for pagination.
     */
    public setFilters(newFilters: IRoomListAPIParams): void {
        this.filters = newFilters
    }

    /**
     * Sets which page of results the iterator should fetch from by default
     * @param currentPage A 1-indexed page number
     */
    public setPage(currentPage: number): void {
        this.currentPage = currentPage
    }

    /**
     * Get the current page that iterator is set to fetch
     */
    public getPage(): number {
        return this.currentPage ?? 1
    }

    /**
     * Gets the currently-active API filters including page offset/limit.
     * @param page Optional, a 1-indexed page number to determine the pagination filters instead of using currentPage
     * @returns A complete IHomepageRoomlistFilters object for use in fetchPage()
     */
    private getCurrentFilters(page?: number): IRoomListAPIParams {
        page = page ?? this.getPage()
        return { ...this.filters, ...getPaginationAPIParams(page, this.pageSize) }
    }

    /**
     * Fetches a full specific page of rooms from the API for the currently-applied filters
     * @param fetchId A unique number corresponding to this fetch request, to resolve race conditions
     * @param page Optional, defaults to currentPage. Mostly present to make sure retried fetches are
     *     self-consistent in which page is requested, you probably don't want to pass this explicitly.
     * @param prefetchPromise Optional, used to handle promise results from roomlist-prefetch package
     * @param attempt Should not be passed explicitly, counts the number of times this specific fetch
     *     has failed and retried. Goes up to CHUNK_RETRY_LIMIT attempts before raising errors.
     * @returns A Promise for the page fetch request
     */
    public fetchPage(fetchId: number, page?: number, prefetchPromise?: Promise<string>, attempt = 0): Promise<IRoomFetchResult> {
        page = page ?? this.currentPage
        const queryParams = new URLSearchParams({
            // Include existing URL params in the query string, particularly for the sake of ?force-<foo> split test params
            ...getNonReservedQueryParamValues(),
            ...(isFilterInPathActive() ? this.filters as Record<string, string> : this.getCurrentFilters(page) as Record<string, string>),
        })
        queryParams.sort()  // Sort params to ensure request URLs with identical filters correspond 1:1
        // If a prefetch promise was passed in, attempt to get the results from that instead of making a new request before retrying
        const pagePromise = prefetchPromise ?? getCb(`${this.apiUrl}?${queryParams.toString()}`).then((xhr) => xhr.responseText)
        // Parse and return the response, or iterate up to FETCH_RETRY_LIMIT if unsuccessful
        return pagePromise.then((response) => this.parseResponse(response, fetchId)).catch((err) => {
            warn("SPA rooms fetch failed", { pageNum: page, attempt: attempt, reason: err })
            if (attempt < FETCH_RETRY_LIMIT) {
                // If we had a prefetchPromise that failed, don't bother passing it through, it's no longer useful
                return this.fetchPage(fetchId, page, undefined, attempt + 1)
            } else {
                throw err
            }
        })
    }

    private parseResponse(response: string, fetchId: number): IRoomFetchResult {
        const jsonMap = new ArgJSONMap(response)
        return {
            loadedRooms: jsonMap.getList("rooms")?.map((room) => {
                return parseIRoomInfo(room)
            }) ?? [],
            matchedCount: jsonMap.getNumber("total_count"),
            totalCount: jsonMap.getNumberOrUndefined("all_rooms_count") ?? 0,
            roomListId: jsonMap.getStringOrUndefined("room_list_id"),
            fetchId,
        }
    }
}
