import { PageType, ShowType, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { Gender, getCurrentGender } from "@multimediallc/gender-utils";
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { ignoreMetaClick } from "../../../common/DOMutils";
import { isFilterInPathActive } from "../../../common/featureFlagUtil";
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"

export interface FollowedOnlineOfflineTabProps {
    online?: boolean
    onFilterClick?: (event: MouseEvent) => void
}

export class FollowedOnlineOfflineTab extends HTMLComponent<HTMLElement, FollowedOnlineOfflineTabProps> {
    // This component manages the bolded & non-bolded text used to toggle between displaying
    // online and offline follwed cams

    protected createElement(props: FollowedOnlineOfflineTabProps): HTMLElement {
        if (isFilterInPathActive()) {
            return <div
                bind={{
                    display: () =>
                        UrlState.current.state.showType === ShowType.FOLLOW || UrlState.current.state.showType === ShowType.FOLLOW_OFFLINE ? "block" : "none",
                }}
                className="followed_online_offline" data-testid="followed-online-offline">
                <FollowedOnlineOfflineFilter onlineFilter={true}/>
                <FollowedOnlineOfflineFilter onlineFilter={false}/>
            </div>
        }
        return <div className="followed_online_offline" data-testid="followed-online-offline">
            <FollowedOnlineOfflineFilter onlineFilter={true} active={props.online ?? true} onFilterClick={props.onFilterClick}></FollowedOnlineOfflineFilter>
            <FollowedOnlineOfflineFilter onlineFilter={false} active={!(props.online ?? true)} onFilterClick={props.onFilterClick}></FollowedOnlineOfflineFilter>
        </div>
    }

    initUI(props?: FollowedOnlineOfflineTabProps): void {
        super.initUI(props)
        UrlState.current.listen(["showType"], () => {
            this.updateState()
        }, this.element)
        this.updateState()
    }
}

interface IFollowedOnlineOfflineFilter {
    active?: boolean
    onlineFilter: boolean
    onFilterClick?: (event: MouseEvent) => void
}

class FollowedOnlineOfflineFilter extends HTMLComponent<HTMLDivElement, IFollowedOnlineOfflineFilter> {
    protected createElement(props: IFollowedOnlineOfflineFilter): HTMLDivElement {
        return <div className={`title${(!isFilterInPathActive() && (props.active ?? false)) ? " bold" : ""}`}
                    bind={isFilterInPathActive() ? {
                        class: () => `title${this.isActive(props) ? " bold" : ""}` } : {}}
                    data-testid={props.onlineFilter ? "online-filter-title" : "offline-filter-title"}
                    onClick={(e) => {
                        ignoreMetaClick(e, () => {
                            if (isFilterInPathActive()) {
                                let targetUrl
                                if (props.onlineFilter) {
                                    targetUrl = UrlState.current.getURLForPartialState({ pageType: PageType.HOME, showType: ShowType.FOLLOW })
                                } else {
                                    // Don't retain any other filter state when navigating to offline followed page, since they won't apply anyway
                                    targetUrl = UrlState.current.convertStateToUrl({ pageType: PageType.HOME, showType: ShowType.FOLLOW_OFFLINE })
                                }
                                // In combination with the above, don't cache the cleared filter state when navigating to the offline tab
                                UrlState.current.navigateTo(targetUrl, !props.onlineFilter)
                            }
                        })
                    }}
        >
            <p>
                <a
                    bind={{ href: () => this.getHref(props.onlineFilter) }}
                    onClick={props.onFilterClick}
                    data-testid={props.onlineFilter ? "online-filter-link" : "offline-filter-link"}
                >{props.onlineFilter ? i18n.onlineRooms : i18n.offlineRooms}</a>
            </p>
        </div>
    }

    initUI(props?: IFollowedOnlineOfflineFilter): void {
        super.initUI(props)
        if (isFilterInPathActive()) {
            UrlState.current.listen(["genders", "showType"], () => this.updateState(), this.element)
        }
    }

    private getHref(online: boolean): string {
        const showType = online ? ShowType.FOLLOW : ShowType.FOLLOW_OFFLINE
        if (isFilterInPathActive()) {
            // If FltInPath is active, cached filters will be auto-applied when navigating to the online followed page, and all other
            // filters will be *ignored* by the offline followed page, so don't bother retaining other filter state params in the HREFs
            const curGender = getCurrentGender()
            if (online && curGender !== Gender.All) {
                return UrlState.current.convertStateToUrl({ pageType: PageType.HOME, showType: showType, genders: [curGender]})
            }
            return UrlState.current.convertStateToUrl({ pageType: PageType.HOME, showType: showType })
        }
        return UrlState.current.getURLForPartialState({ pageType: PageType.HOME, showType: showType })
    }

    private isActive(props: IFollowedOnlineOfflineFilter): boolean {
        return props.onlineFilter ? UrlState.current.state.showType === ShowType.FOLLOW : UrlState.current.state.showType === ShowType.FOLLOW_OFFLINE
    }
}
